import {
  REGISTER_USER_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_STUDENT
} from "../Action Creators/userConstants";

  const initialState = {
    isLoggedIn: false,
    student : null,
    error: null,
  };

// Define the reducer function
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        student: action.payload,
        loading: false,
        isAuthenticated: true,
      };
    case LOGOUT_STUDENT:
      return {
        ...state,
        isLoggedIn: false,
        student: null,
        loading: false,
        isAuthenticated: false,
      };

   

    default:
      return state;
  }
};

export default authReducer;
