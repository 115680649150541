import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import Logo from "../../Assets/logo.png"
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useLocation} from "react-router-dom"
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import BugReportSharpIcon from '@mui/icons-material/BugReportSharp';


const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const location = useLocation();

  return (
    <div className="sidebar">
      <div className="top">
        <Link className="logo" to="/" style={{ textDecoration: "none" }}>
        
            <img src={Logo} alt="" />
         
        </Link>
        <h5 style={{textTransform:"uppercase",fontWeight:"bolder"}}>Student Panel</h5>
      </div>
      {/* <hr /> */}


      <div className="center">
        <ul>


          <Link to="/" style={{ textDecoration: "none" }}>
            <li style={{background: location.pathname === "/" ? "rgb(231 220 220)" : ""    }} >
              <AutoStoriesIcon className="icon" />
              <span>Sample Papers</span>
            </li>
          </Link>
          <Link to="/final-papers" style={{ textDecoration: "none" }}>
            <li style={{background: location.pathname === "/final-papers" ? "rgb(231 220 220)" : ""    }}>
              <AutoStoriesIcon className="icon" />
              <span>Final Papers</span>
            </li>
          </Link>
          <Link to="/records" style={{ textDecoration: "none" }}>
            <li style={{background: location.pathname === "/records" ? "rgb(231 220 220)" : ""    }}>
              <SummarizeIcon className="icon" />
              <span>Reports</span>
            </li>
          </Link>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <li style={{background: location.pathname === "/profile" ? "rgb(231 220 220)" : ""    }}>
              <AccountCircleIcon className="icon" />
              <span>Profile</span>
            </li>
          </Link>
          <Link to={`/rules`} style={{ textDecoration: "none" }}>
            <li
               style={{
                background:
                  location.pathname === "/rules" ? "rgb(231 220 220)" : "",
              }}
            >
              <PsychologyAltIcon className="icon" />
              <span>Rules</span>
            </li>
          </Link>
          <Link to={`/report-issue`} style={{ textDecoration: "none" }}>
            <li
               style={{
                background:
                  location.pathname === "/report-issue" ? "rgb(231 220 220)" : "",
              }}
            >
              <BugReportSharpIcon className="icon" />
              <span>Report An Issue</span>
            </li>
          </Link>

          <Link to={`/marked/questions`} style={{ textDecoration: "none" }}>
            <li
               style={{
                background:
                  location.pathname === "/marked/questions" ? "rgb(231 220 220)" : "",
              }}
            >
              <BugReportSharpIcon className="icon" />
              <span>Wrong Marked Questions </span>
            </li>
          </Link>

      



        </ul>
      </div>
   
    </div>
  );
};

export default Sidebar;
