import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducers'; // Import the root reducer
import {persistReducer,persistStore} from "redux-persist"
import storage from "redux-persist/lib/storage"
const persistConfig = {
    key : 'persist-store',
    storage
}

const persistedReducer = persistReducer(persistConfig,rootReducer);

const store = createStore(persistedReducer, composeWithDevTools()); // Create the store with the root reducer

export const persistor = persistStore(store);




export default store; // Export the store