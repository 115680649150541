import { DeveloperBoard } from "@mui/icons-material";
import { createContext, Fragment, useReducer } from "react";
import DarkModeReducer from "./darkModeReducer";

const INITIAL_STATE = {
  darkMode: false,
};

export function greet(){
  return (
    <Fragment
    >


   Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, sapiente.
    also this would be greate fit for the  
    </Fragment>
  )
}
export const DarkModeContext = createContext(INITIAL_STATE);

export const DarkModeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DarkModeReducer, INITIAL_STATE);

  return (
    <DarkModeContext.Provider value={{ darkMode: state.darkMode, dispatch }}>
      {children}
    </DarkModeContext.Provider>
  );
};


