import Home from "./pages/home/Home";
import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./style/dark.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { DarkModeContext } from "./context/darkModeContext";
const Record = lazy(() => import("./pages/Records/Record"));

const SamplePaper = lazy(() =>
  import("./components/samplePapersPage/SamplePaper")
);
const ThankYou = lazy(() =>
  import("./pages/Thank You Page/ThankYou")
);

const StudentLogin = lazy(() => import("./pages/login/LoginStudent"));

const RegisterStudent = lazy(() =>
  import("./pages/Register Student/RegisterStudent")
);
const Questions = lazy(() => import("./components/questions/Questions"));
const Score = lazy(() => import("./components/Score/Score"));
const FinalPaper = lazy(() =>
  import("./components/Score/Final Papers/FinalPaper")
);
const AnalyisPaper = lazy(() =>
  import("./components/analyis paper/AnalyisPaper")
);
const Profile = lazy(() => import("./pages/profile/Profile"));
const ProtectedRoute = lazy(() =>
  import("./components/Protected route/ProtectedRoutes")
);

const Rules = lazy(() => import("./pages/Rules/Rules"));
const ForgotPassword = lazy(() =>
  import("./pages/Forgot Password/ForgotPassword")
);
const SetNewPassword = lazy(() =>
  import("./pages/Set New Password/SetNewPassword")
);
const PageNotFound = lazy(() =>
  import("./pages/404/NotFound")
);

const ReportIssue = lazy(() =>
  import("./pages/Report Issue/ReportIssue")
);
const WrongMarkedQuestions = lazy(() =>
  import("./pages/Wrong Marked Questions/WrongMarkedQuestions")
);





function App() {
  const { darkMode } = useContext(DarkModeContext);
  // const navigate = useNavigate();
  const isStudentLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  // useEffect(()=>{
  //   if(!isStudentLoggedIn){
  //     navigate("/login")
  //   }
  // },[])
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <Suspense fallback={ <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />

        
      </Backdrop>}>
        <BrowserRouter>
          <Routes>
            <Route
              element={<ProtectedRoute isAuthenticated={isStudentLoggedIn} />}
            >
              <Route path="/" element={<Home />} />
              <Route path="/records" element={<Record />} />
              <Route path="/questions" element={<Questions />} />
              <Route path="/score" element={<Score />} />
              <Route path="/final-papers" element={<FinalPaper />} />
              <Route path="/samplepaper/:paperId" element={<SamplePaper />} />
              <Route
                path="/analyis-paper/:paperId"
                element={<AnalyisPaper />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/rules" element={<Rules />} />
              <Route path="/report-issue" element={<ReportIssue />} />
              <Route path="/marked/questions" element={<WrongMarkedQuestions />} />
            </Route>

            <Route path="/login" element={<StudentLogin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route
              path="/new-password/:stuId/:token"
              element={<SetNewPassword />}
            />
            <Route path="/register" element={<RegisterStudent />} />
            <Route path="/*" element={<PageNotFound/>} />
          </Routes>
        </BrowserRouter>
      </Suspense>

      <ToastContainer />
    </div>
  );
}

export default App;
