import "./navbar.scss";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import {useSelector} from "react-redux"
import {useDispatch} from "react-redux"
import { toast } from "react-toastify";
import axios from "axios";

const Navbar = () => {
  const dispatch = useDispatch();
  const [student,setStudent] = useState();
  const [shouldFullScree, setShouldFullScreen] = useState(false);
  const studentData = useSelector((state)=> state.auth.student.student)
  function logoutHandler(){
      dispatch({type: "LOGOUT_STUDENT"})
      toast.success("Logged Out Succesfully!");
  }

  useEffect(()=>{
    async function getLogedUserDetails(){
      const {data} = await axios.get(`https://api.ascentabacus.com/get-student/${studentData?._id}`);
      if(data){
        setStudent(data?.student);
      }
    }
    getLogedUserDetails()
  },[])

  function toggleFullScreen() {
    // This gives the whole Dom or html Doc
    const elem = document.documentElement;
    if (
      !document.fullscreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // Request fullscreen
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  }

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="items">
         
         <div className="one">
         <div className="item">
            <FullscreenExitOutlinedIcon
            style={{cursor:"pointer"}}
              onClick={toggleFullScreen}
              className="icon"
            />
          </div>

          <div className="item " id="user" style={{display:"flex",alignItems:"center !important",gap:"10px"}}>
            {/* <img
              src="https://avatars.githubusercontent.com/u/71833197?v=4"
              alt=""
              className="avatar"
            /> */}
             <Avatar id="avatarimg"name={`${student?.firstname} ${student?.lastname}`} size="30px" round /> 
              <p className="mb-0">{`${student?.firstname} ${student?.lastname}`}</p> 
          </div>
         </div>
         <div className="second">
            <button onClick={logoutHandler}> <LogoutIcon style={{marginRight:"5px"}}/>  logout</button>
         </div>


        </div>
      </div>
    </div>
  );
};

export default Navbar;
